<div class="tableautin-group">
  <div class="label">{{config.selectedTableautin?.Designation}}</div>
  <div class="tableautin">
    <div class="line" *ngFor="let row of tableautin">
      <div *ngIf="row[0]?.name !== 'empty'" class="components">
        <div
          *ngFor="let element of row"
          class="element size{{element.nbModules}}"
          [ngClass]="{'reserve' : element.name === 'modules reservés'}"
        >
          <div class="name">{{element.name}}</div>
        </div>
      </div>

      <div class="grid">
        <div *ngFor="let i of [].constructor(config.selectedTableautin?.NbModuleParRangee)"></div>
      </div>
    </div>
  </div>
</div>
