import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { switchMap } from 'rxjs';
import { User } from '@depagne/types';
import { Router } from '@angular/router';
import { ForgotPasswordModalComponent } from '../../components/forgot-password-modal/forgot-password-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../../../dashboard/services/project.service';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../configurator/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    username: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });
  error: string | null = null;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private dataService: DataService,
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    const { username, password } = this.loginForm.value;
    this.userService
      .login(username as string, password as string)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          this.userService.setTokens(response.access_token, response.refresh_token);

          return this.userService.getUserData();
        }),
      )
      .subscribe({
        next: (data) => {
          this.userService.firstRefreshMade = true;
          this.error = null;
          console.log(data);
          this.userService.storeUser(data as User);
          this.userService.checkCGU(data as User);
          this.projectService.loadProjectsForUser();
          this.dataService.loadAllData();
          this.router.navigate(['dashboard']);
        },
        error: async (error) => {
          // ToDo show toast depending on error, login or getting user data
          console.log('error:', error);
          this.error = error?.error?.message || error;

          if (error.status === 401) {
            this.error = `Le nom d'utilisateur et le mot de passe que vous avez entrés ne correspondent pas à ceux présents dans nos fichiers. Veuillez vérifier et réessayer.`;
          }
        },
      });
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordModalComponent);
  }

  protected readonly environment = environment;
}
