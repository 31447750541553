import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, firstValueFrom, Observable, take, tap } from 'rxjs';
import { ClientUser, CommercialUser, FullUser, User, UserRole } from '@depagne/types';
import { ToastService } from './toast.service';
import { SimpleOkModalComponent } from '../components/simple-ok-modal/simple-ok-modal.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReadCGUModalComponent } from '../components/read-cgu-modal/read-c-g-u-modal.component';
import { AcceptCGUModalComponent } from '../components/accept-cgu-modal/accept-c-g-u-modal.component';
import { isBefore } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  authenticationToken!: string | null;
  refreshToken!: string | null;
  user$: BehaviorSubject<User | null | any> = new BehaviorSubject(null);
  public firstRefreshMade = false;

  constructor(private http: HttpClient, private toastService: ToastService, private router: Router, private dialog: MatDialog) {
    console.log('load user data');
    this.authenticationToken = localStorage.getItem('authentication_token') || null;
    this.refreshToken = localStorage.getItem('refresh_token') || null;
    let user: string | User | null = localStorage.getItem('user');

    if (user && user !== 'undefined') {
      user = JSON.parse(user) as User;
      console.log(user);
      this.user$.next(user);
    }
  }

  login(username: string, password: string) {
    return this.http.post(`${environment.api}/auth/login`, { username, password });
  }

  updateUser(user: Partial<User>, role: string) {
    return this.http.put(`${environment.api}/user/${role}/${user.id}`, { ...user }).pipe(
      tap(() => {
        this.storeUser(user as CommercialUser);
        this.toastService.showToast('success', 'Utilisateur mise a jour');
      }),
    );
  }

  modifyClientUser(user: Partial<User>, role: string) {
    return this.http.put(`${environment.api}/user/${role}/${user.id}`, { ...user }).pipe(
      tap(() => {
        this.toastService.showToast('success', 'Utilisateur mise a jour');
      }),
    );
  }

  createClientUser(user: Partial<ClientUser>) {
    return this.http.post(`${environment.api}/user/create/client`, { ...user, codePostal: user.codePostal?.toString() });
  }

  setTokens(authenticationToken: string, refreshToken: string) {
    this.authenticationToken = authenticationToken;
    localStorage.setItem('authentication_token', authenticationToken);
    this.refreshToken = refreshToken;
    localStorage.setItem('refresh_token', refreshToken);
  }

  getUserData(): Observable<User> {
    return this.http.get(environment.api + '/profile') as Observable<User>;
  }

  getRefreshedTokens() {
    return this.http.post(environment.api + '/auth/refresh', {
      refresh_token: this.refreshToken,
    });
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  storeUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    this.user$.next(user);
  }

  refreshUserData() {
    return this.getUserData();
  }

  forgotPassword(email: string) {
    return this.http.post(`${environment.api}/auth/reset-password`, { email });
  }

  static isClient(user: User) {
    return user.role === UserRole.User;
  }
  static isCommercial(user: User) {
    return user.role === UserRole.Commercial || user.role === UserRole.Admin;
  }
  static isPartenaireCommercial(user: CommercialUser) {
    if (user.type) {
      if (user.type === 'partenaire') {
        return true;
      } else {
        return false;
      }
    } else if (this.isCommercial(user) && user.email.indexOf('@depagne.fr') === -1) {
      return true;
    }
    return false;
  }
  static isNotDepagneCommercial(user: CommercialUser) {
    return user.type !== 'depagne';
  }
  static isAdmin(user: User) {
    return user.role === UserRole.Admin;
  }

  static getHttpRole(user: FullUser) {
    let role = '';

    if (UserService.isClient(user)) {
      role = 'client';
    } else if (UserService.isPartenaireCommercial(user)) {
      role = 'partenaire';
    } else if (UserService.isCommercial(user)) {
      role = 'commercial';
    }

    return role;
  }

  deleteUser(user: ClientUser) {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: {
        text: "Attention, vous allez supprimer votre compte. Vous n'aurez plus accès au logiciel et vos devis. Tous vos projets en brouillon seront supprimés. Êtes vous-sûr ?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
        // delete user
        this.http.delete(`${environment.api}/user/user/${user.id}`).subscribe((response) => {
          console.log(response);
          this.logout();
          this.router.navigateByUrl('/login');
        });
      }
    });
  }

  async promptCGU() {
    const dialogRef = this.dialog.open(AcceptCGUModalComponent, { disableClose: true });

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result.ok) {
      return true;
    }

    return false;
  }

  getClientUsers(): Observable<FullUser[]> {
    return this.http.get(environment.api + '/user/get-all-clients') as Observable<FullUser[]>;
  }

  getAllClientUsers(): Observable<FullUser[]> {
    return this.http.get(environment.api + '/user/') as Observable<FullUser[]>;
  }

  getAllCommercialUsers(): Observable<FullUser[]> {
    return this.http.get(environment.api + '/user/commercials') as Observable<FullUser[]>;
  }

  inviteCommercial(payload: any) {
    return this.http.post(environment.api + '/user/invite/commercial', payload) as Observable<FullUser[]>;
  }

  getInviteCommercial(token: string) {
    return this.http.get(environment.api + '/user/invite/commercial/' + token) as Observable<FullUser>;
  }

  postInviteCommercial(token: string, payload: CommercialUser) {
    return this.http.post(`${environment.api}/user/invite/commercial/${payload.type}/${token}`, payload);
  }

  async checkCGU(user: User) {
    if (!user.cguApproved || isBefore(Date.parse(user.cguApproved as unknown as string), Date.parse(environment.lastCguUpdate))) {
      const result = await this.promptCGU();
      if (result) {
        this.http.put(`${environment.api}/user/cgu/${user.id}`, {}).subscribe(() => {
          this.toastService.showToast('success', 'Utilisateur cgu mise a jour');
        });
      }
    }
  }
}
