<div class="modal">
  <div class="modal-header">
    <h1>Nouveau projet</h1>
  </div>
  <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p class="mini-label">Saisie obligatoire *</p>
      <div class="input-group">
        <label for="company">client*</label>
        <input id="company" type="text" formControlName="company" />
      </div>
      <div class="input-group">
        <label for="client">Nom du contact*</label>
        <input id="client" type="text" formControlName="client" />
      </div>
      <div class="input-group">
        <label for="name">Nom du Projet*</label>
        <input id="name" type="text" formControlName="name" />
      </div>
      <div class="input-group">
        <label for="description">description </label>
        <input id="description" type="text" formControlName="description" />
      </div>
      <div class="input-group">
        <label for="reduction">Remise client en %</label>
        <input id="reduction" min="0" max="100" type="number" formControlName="reduction" />
      </div>
      <div class="modal-footer">
        <button mat-dialog-close>annuler</button>
        <button class="validation-button" type="submit" [disabled]="!projectForm.valid">valider</button>
      </div>
    </div>
  </form>
</div>
