<h2>Enveloppes</h2>
<p *ngFor="let item of data.enveloppeData">
  {{item.id}}<br />{{item.Designation}}<br />{{item.VISUELS}}<img src="assets/images/configurator/{{item.VISUELS}}.png" />
</p>
<p *ngFor="let item of data.platineCommandeData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/configurator/{{item.id}}.png" />
</p>
<h2>Disjoncteurs</h2>
<p *ngFor="let item of data.disjoncteurData">
  {{item.id}}<br />{{item.Designation}}<br />
  {{item.TypeAlimentation}} <img src="assets/images/configurator/{{item.TypeAlimentation}}-{{item.id}}.png" />
</p>
<h2>Kits Barrette</h2>
<p *ngFor="let item of data.kitBarretteData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/configurator/{{item.id}}.png" />
</p>
<h2>Kits Eclairage</h2>
<p *ngFor="let item of data.kitEclairageData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/configurator/{{item.id}}.png" />
</p>
<h2>Kits Prise</h2>
<p *ngFor="let item of data.kitPriseData">{{item.id}}<br />{{item.Designation}} <img src="assets/images/configurator/{{item.id}}.png" /></p>
<h2>Parafoudres</h2>
<p>Il y a juste des images: MONO-oui, MONO-oui-500,TETRA-oui, TETRA-oui-500,</p>
<p>
  MONO-oui <img src="assets/images/configurator/MONO-oui.png" /> MONO-oui-500
  <img src="assets/images/configurator/MONO-oui-500.png" /> TETRA-oui <img src="assets/images/configurator/MONO-oui.png" /> TETRA-oui-500
  <img src="assets/images/configurator/MONO-oui-500.png" />
</p>
<h2>Materiel Gestion</h2>
<p *ngFor="let item of data.materielGestionData">
  {{item.id}}<br />{{item.Designation}} <img src="assets/images/configurator/{{item.id}}.png" />
</p>
