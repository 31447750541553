import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  public showToast(severity: 'success' | 'info' | 'warn' | 'error', summary: string, detail?: string) {
    this.messageService.add({ severity, summary, detail, sticky: severity === 'error' });
  }
}
