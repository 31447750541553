import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Configuration, ConfigurationElectronicDetails, ConfigurationItem, User } from '@depagne/types';

@Component({
  selector: 'app-visualize-config',
  templateUrl: './visualize-config-modal.component.html',
  styleUrls: ['./visualize-config-modal.component.scss'],
})
export class VisualizeConfigModalComponent implements OnInit {
  public config!: ConfigurationElectronicDetails;
  public configList!: ConfigurationItem[];
  public showPrice = false;

  constructor(
    public dialogRef: MatDialogRef<VisualizeConfigModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      config: Configuration;
      showPrice: boolean;
    },
  ) {
    this.config = data.config.details;
    this.configList = this.config.configurationItems;
    this.showPrice = data.showPrice;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
