import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';

export function createCodePostalValidator2(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isCodePostal = /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/.test(value);

    const passwordValid = isCodePostal;

    return !passwordValid ? { passwordStrength: true } : null;
  };
}

export function createCodePostalValidator(control: AbstractControl): Observable<ValidationErrors | null> {
  const v: string = control.value;
  if (!/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/.test(v)) {
    // Emit an object with a validation error.
    return of({ notCodePostal: true });
  }
  // Emit null, to indicate no error occurred.
  return of(null);
}
