import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { HttpRequestService } from './shared/services/http-request.service';
import { DebugModule } from './debug/debug/debug.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

registerLocaleData(localeFr, 'fr');

if (environment.production) {
  Sentry.init({
    dsn: 'https://0ff8b86545764e508eaaada2beccdc19@glitchtip.tobik.eu/1',
    environment: environment.production ? 'production' : 'develop',
    release: environment.version,
    autoSessionTracking: false,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    SharedModule,
    DebugModule,
    ToastModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: ErrorHandler,
      useValue: environment.production ? Sentry.createErrorHandler() : new ErrorHandler(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestService, multi: true },
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
