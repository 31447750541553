<div class="modal">
  <div class="modal-header">
    <h1>AJOUTER UN ARTICLE COMPLEMENTAIRE</h1>
  </div>
  <form [formGroup]="lineForm">
    <div class="modal-body">
      <div class="input-group">
        <label for="name">ARTICLE</label>
        <input id="name" type="text" formControlName="name" />
      </div>
      <div class="input-group">
        <label for="price">PRIX EN EUROS </label>
        <input id="price" min="0" type="number" formControlName="price" />
      </div>

      <input type="file" class="file-input" accept="application/pdf" (change)="onFileSelected($event)" #fileUpload />

      <div class="file-upload">
        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">ajouter un PDF</button>
        {{fileName || "aucun fichier pdf ajouté."}}
      </div>

      <div class="modal-footer">
        <button mat-dialog-close>annuler</button>
        <button class="validation-button" type="submit" [disabled]="!lineForm.valid" (click)="onSubmit()">valider</button>
      </div>
    </div>
  </form>
</div>
