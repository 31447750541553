import { Project } from './project';

export enum UserRole {
  User = 'user',
  Commercial = 'commercial',
  Admin = 'admin',
  VisualAdmin = 'visualadmin',
  Technician = 'technician',
}

export interface User {
  id: number;
  role: UserRole;
  name?: string;
  company?: string;
  address?: string;
  codePostal?: string;
  city?: string;
  siret?: string;
  tva?: string;
  creationDate: Date;
  email: string;
  siteweb: string;
  phone: string;
  password: string;
  lastConnection: Date;
  valid: boolean;
  projects: Project[];
  cguApproved?: Date;
  deleted: Date;
  blocked: boolean;
}

export interface ClientUser extends User {
  workFunction: string;
  reduction: number;
}

export interface CommercialUser extends User {
  type: 'partenaire' | 'depagne' | string;
  logo: string;
}

export interface FullUser extends User, ClientUser, CommercialUser {}

export interface Affectation {
  codePostal: string;
  commercial1: string;
  commercial2: string;
}
