<div class="modal">
  <div class="modal-body">
    <div *ngIf="data.image" class="img-modal">
      <img [src]="'assets/images/' + data.image" />
    </div>
    <p>{{data.text}}</p>
  </div>
  <div class="modal-footer" *ngIf="data.prompt">
    <button (click)="ok()">ok</button>
  </div>
  <div class="modal-footer" *ngIf="!data.prompt">
    <button (click)="cancel()">annuler</button>
    <button (click)="ok()">valider</button>
  </div>
</div>
