import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private userService: UserService, private router: Router, private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes('login') ||
      request.url.includes('/user/create') ||
      request.url.includes('/invite/') ||
      request.url.includes('assets') ||
      request.url.includes('reset-password')
    ) {
      return next.handle(request);
    }

    if (!this.userService.authenticationToken && !this.userService.refreshToken) {
      this.router.navigateByUrl('login');
      return throwError('no authentication token');
    }

    // if there is a problem with the refresh_token logout, otherwise broken state of app
    if (request.url.includes('refresh')) {
      const authReq = this.addToken(request, this.userService.refreshToken as string);

      return next.handle(authReq).pipe(
        catchError((error) => {
          this.userService.logout();
          return throwError(error);
        }),
      );
    }

    const authReq = this.addToken(request, this.userService.authenticationToken as string);

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        }

        let message = '';
        let detail = '';

        if (error.message) {
          message = error.message;

          if (error.error.message) {
            detail = error.error.message;
          }
        } else {
          message = JSON.stringify(error);
        }

        this.toastService.showToast('error', message, detail);

        return throwError(error);
      }),
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('refresh token');
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userService.getRefreshedTokens().pipe(
        switchMap((token: any) => {
          this.userService.setTokens(token.access_token, token.refresh_token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          return next.handle(this.addToken(request, token.access_token));
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => next.handle(this.addToken(request, token))),
      );
    }
  }
}
