import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ClientUser, CommercialUser, User } from '@depagne/types';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReadCGUModalComponent } from '../../components/read-cgu-modal/read-c-g-u-modal.component';
import { SimpleOkModalComponent } from '../../components/simple-ok-modal/simple-ok-modal.component';
import { createCodePostalValidator } from '../../validators/form.validators';

@Component({
  selector: 'app-inscription-client',
  templateUrl: './inscription-commercial.component.html',
  styleUrls: ['./inscription-commercial.component.scss'],
})
export class InscriptionCommercialComponent implements OnInit {
  inscriptionForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      company: ['', Validators.required],
      address: ['', Validators.required],
      codePostal: ['', Validators.required, createCodePostalValidator],
      city: ['', Validators.required],
      siret: ['', Validators.required],
      tva: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required],
      password_repeated: ['', Validators.required],
      cgu: [false, [Validators.required, Validators.requiredTrue]],
    },
    {
      validators: checkPasswords,
    },
  );
  error: string | null = null;
  token: string;
  type!: 'depagne' | 'partenaire' | string;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.token = this.route.snapshot.paramMap.get('token') as string;
    console.log(this.token);

    this.userService.getInviteCommercial(this.token).subscribe(
      (result) => {
        console.log(result);
        this.type = result.type;
        this.inscriptionForm.patchValue({ email: result.email });

        if (result.type === 'depagne') {
          console.log('remove');
          this.inscriptionForm.get('company')?.clearValidators();
          this.inscriptionForm.get('company')?.updateValueAndValidity();
          this.inscriptionForm.get('address')?.clearValidators();
          this.inscriptionForm.get('address')?.updateValueAndValidity();
          this.inscriptionForm.get('codePostal')?.clearValidators();
          this.inscriptionForm.get('codePostal')?.clearAsyncValidators();
          this.inscriptionForm.get('codePostal')?.updateValueAndValidity();
          this.inscriptionForm.get('city')?.clearValidators();
          this.inscriptionForm.get('city')?.updateValueAndValidity();
          this.inscriptionForm.get('tva')?.clearValidators();
          this.inscriptionForm.get('tva')?.updateValueAndValidity();
          this.inscriptionForm.get('siret')?.clearValidators();
          this.inscriptionForm.get('siret')?.updateValueAndValidity();
        }
        this.getFormValidationErrors();
      },
      (error) => {
        const dialogRef = this.dialog.open(SimpleOkModalComponent, {
          data: { text: 'Invitation pas trouvé, merci de contacter Depagne.', prompt: true },
        });
        this.router.navigateByUrl('/login');
      },
    );
  }

  ngOnInit(): void {}

  onSubmit() {
    this.userService
      .postInviteCommercial(this.token, { ...this.inscriptionForm.value, type: this.type } as CommercialUser)
      .subscribe((result) => {
        console.log(result);
        this.showSubmitSuccess();
        this.router.navigateByUrl('/login');
      });
  }

  readCGU(client: 'client' | 'commercial') {
    const dialogRef = this.dialog.open(ReadCGUModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
      }
    });
  }

  showSubmitSuccess() {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: { text: `Merci d'avoir crée votre compte!`, prompt: true, image: 'configurator/END.png' },
    });
  }

  getFormValidationErrors() {
    Object.keys(this.inscriptionForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors | null | undefined = this.inscriptionForm.get(key)?.errors;
      if (controlErrors != null && controlErrors != undefined) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}

const checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  let pass = group.get('password')?.value;
  let password_repeated = group.get('password_repeated')?.value;
  return pass === password_repeated ? null : { passwordNoMatch: true };
};
