<div class="modal">
  <div class="modal-header">
    <h1>Finaliser le prix remisé</h1>
  </div>
  <div class="modal-body">
    <p class="text">Le tarif est calculé automatiquement suivant la grille tarifaire en vigueur mise à jour le XXX.</p>
    <div class="group-top">
      <div class="group-price">
        <span class="label">prix total sans remise</span>
        <div class="price">{{ project.nonReducedPrice | number:'0.2-2' }}<span>€</span></div>
      </div>
      <!--
    <ul><ng-container *ngFor="let config of project.configurations">
        <li>{{config.name}} {{config.nomenclature?.totalPrice}}</li>
        <li *ngFor="let additionalInvoice of config.additions">{{additionalInvoice.name}} {{additionalInvoice.price}}</li>
      </ng-container>
    </ul>
    -->
      <div class="input-group remise">
        <label for="reduction">remise générale client en %</label>
        <div class="remise-group">
          <input (focus)="onFocus()" (blur)="onBlur()" id="reduction" min="0" max="100" type="number" [(ngModel)]="project.reduction" />
          <button class="validation-button">appliquer</button>
        </div>
      </div>
    </div>
    <div class="group-bottom">
      <div class="group-price">
        <span class="label total">prix total avec remise</span>
        <div class="price-remise">
          <div class="icon"></div>
          <div class="price total">{{project.price | number:'0.2-2'}}<span>€</span></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button mat-dialog-close>retour</button>
    <button class="validation-button" [disabled]="hasFocus" (click)="sendProjectToClient()">envoyer</button>
  </div>
</div>
