import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-startpage-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './startpage-container.component.html',
  styleUrl: './startpage-container.component.scss',
})
export class StartpageContainerComponent {
  protected readonly environment = environment;
}
