import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '@depagne/types';
import { calculateNonReducedProjectPrice } from '@depagne/utils';

@Component({
  selector: 'app-send-devis-modal',
  templateUrl: './send-devis-modal.component.html',
  styleUrls: ['./send-devis-modal.component.scss'],
})
export class SendDevisModalComponent implements OnInit {
  project!: Project;
  hasFocus = false;

  constructor(
    public dialogRef: MatDialogRef<SendDevisModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project;
    },
  ) {
    console.log(data.project);
    this.project = JSON.parse(JSON.stringify(data.project));
    this.project = calculateNonReducedProjectPrice(this.project);
  }

  ngOnInit(): void {}

  onFocus() {
    this.hasFocus = true;
  }

  onBlur() {
    this.project = calculateNonReducedProjectPrice(this.project);
    setTimeout(() => {
      this.hasFocus = false;
    }, 200);
  }

  sendProjectToClient() {
    if (this.hasFocus) return;

    this.dialogRef.close({
      ok: true,
      project: this.project,
    });
  }
}
