<app-startpage-container>
  <form [formGroup]="loginForm">
    <div class="modal-body">
      <div class="input-group">
        <label for="name">Email</label>
        <input id="name" type="text" formControlName="username" />
      </div>
      <div class="input-group">
        <label for="description">Mot de passe </label>
        <input id="description" type="password" formControlName="password" />
      </div>
      <p class="error" *ngIf="error">{{error}}</p>

      <div class="form-footer">
        <button class="validation-button" type="submit" [disabled]="!loginForm.valid" (click)="onSubmit()">Connexion</button>
        <button class="xl" (click)="forgotPassword()">Mot de passe oublié ?</button>
      </div>
    </div>
  </form>
  <div class="form-container" *ngIf="!environment.hideClientInscription">
    <p class="intro">Vous voulez accéder à notre configurateur TERRA ? Inscrivez-vous ici</p>
    <div class="form-footer">
      <button class="validation-button" [routerLink]="'/inscription/client'">Inscription</button>
    </div>
  </div>
</app-startpage-container>
