import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdditionalInvoiceLine, Project } from '@depagne/types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-project-modal',
  templateUrl: './add-invoice-line-modal.component.html',
  styleUrls: ['./add-invoice-line-modal.component.scss'],
})
export class AddInvoiceLineModalComponent implements OnInit {
  lineForm = this.fb.group({
    name: ['', Validators.required],
    price: [0, [Validators.required, Validators.min(0)]],
  });
  fileName = '';

  constructor(
    public dialogRef: MatDialogRef<AddInvoiceLineModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      line: AdditionalInvoiceLine;
      editPrice: boolean;
    },
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    const { name, price, file } = data.line;

    if (!data.editPrice) {
      this.lineForm.get('price')?.disable();
    }

    console.log(data.line);

    this.fileName = file;

    this.lineForm.patchValue({
      name,
      price,
    });
  }

  ngOnInit(): void {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      const upload$ = this.http.post(`${environment.api}/project/upload-file`, formData);

      upload$.subscribe({
        next: (data: any) => {
          console.log(data);
          this.fileName = data.filename;
        },
        error: (error) => {
          console.log(error);
          this.fileName = '';
        },
      });
    }
  }

  onSubmit() {
    console.log(this.lineForm.value);
    this.dialogRef.close({
      ...this.data.line,
      ...this.lineForm.value,
      file: this.fileName,
    });
  }
}
