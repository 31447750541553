<div class="modal">
  <div class="modal-body">
    <div class="depagne-logo"></div>
    <p>Les Conditions Générales d'Utilisation (C.G.U.) du configurateur TERRA ont été mises à jour.</p>
    <button class="link" (click)="readCGU('client')">Consulter les C.G.U.</button>
    <p>Merci d'accepter pour continuer l'utilisation du configurateur.</p>
  </div>
  <div class="modal-footer">
    <button (click)="ok()">accepter</button>
  </div>
</div>
