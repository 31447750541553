<div class="front-layout">
  <div class="image-container">
    <div class="sublogo-group">
      <h1>TERRA</h1>
      <h2>CONFIGURATEUR D’ARMOIRES ET COFFRETS<br />D’ÉCLAIRAGE PUBLIC</h2>
    </div>
    <img src="/assets/images/background_image.png" alt="" />
  </div>
  <div class="content-container">
    <div class="logo"></div>
    <div class="form-container">
      <p class="intro">
        Configurez en toute simplicité vos armoires et coffrets d’Eclairage Public en conformité avec les normes en vigueur (NF C 14-100 et
        17-200)
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</div>
