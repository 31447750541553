<app-startpage-container>
  <form [formGroup]="inscriptionForm">
    <div class="modal-body">
      <div class="input-group">
        <label for="email">Email</label>
        <input id="email" type="email" formControlName="email" [readOnly]="true" />
      </div>
      <div class="input-group">
        <label for="name">Nom Contact <span class="required">*</span></label>
        <input id="name" type="text" formControlName="name" />
      </div>
      <div class="input-group" *ngIf="type === 'partenaire'">
        <label for="company">Nom Enterprise <span class="required">*</span></label>
        <input id="company" type="text" formControlName="company" />
      </div>
      <div class="input-group" *ngIf="type === 'partenaire'">
        <label for="address">Adresse <span class="required">*</span></label>
        <input id="address" type="text" formControlName="address" />
      </div>
      <div class="input-group" *ngIf="type === 'partenaire'">
        <label for="codePostal">Code postal <span class="required">*</span></label>
        <input id="codePostal" type="text" pattern="[0-9]*" formControlName="codePostal" />
      </div>
      <div class="input-group" *ngIf="type === 'partenaire'">
        <label for="city">Ville <span class="required">*</span></label>
        <input id="city" type="text" formControlName="city" />
      </div>
      <div class="input-group">
        <label for="telephone">TELEPHONE <span class="required">*</span></label>
        <input id="telephone" type="tel" formControlName="phone" />
      </div>
      <div class="input-group" *ngIf="type === 'partenaire'">
        <label for="siret">N° SIRET <span class="required">*</span></label>
        <input id="siret" type="text" formControlName="siret" />
      </div>
      <div *ngIf="type === 'partenaire'" class="input-group">
        <label for="tva">N° TVA </label>
        <input id="tva" type="text" formControlName="tva" />
      </div>
      <div class="input-group">
        <label for="description">Mot de passe <span class="required">*</span></label>
        <input id="description" type="password" formControlName="password" />
      </div>
      <div class="input-group">
        <label for="password_repeated">Confirmation de mot de passe <span class="required">*</span></label>
        <input id="password_repeated" type="password" formControlName="password_repeated" />
        <p *ngIf="inscriptionForm.hasError('passwordNoMatch')">Mot de passe pas identique</p>
      </div>

      <p>Veuillez consulter et accepter nos conditions générales d'utilisation pour finaliser votre inscription.</p>
      <div class="cgu-group">
        <button class="back-button" (click)="readCGU('client')">Consulter les CGUs</button>
        <div class="check-group">
          <div class="input-group">
            <input id="cgu" type="checkbox" formControlName="cgu" />
          </div>
          <p>j'accepte</p>
        </div>
      </div>
      <div class="form-footer">
        <button class="back-button" [routerLink]="'/login'">retour</button>
        <button class="validation-button" type="submit" (click)="onSubmit()" [disabled]="!inscriptionForm.valid">S'inscrire</button>
      </div>
    </div>
  </form> </app-startpage-container
>>
