import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleOkModalComponent } from './components/simple-ok-modal/simple-ok-modal.component';
import { ConfigurationItemComponent } from './components/configuration-item/configuration-item.component';
import { TableautinComponent } from './components/tableautin/tableautin.component';
import { LoginComponent } from './containers/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ForgotPasswordModalComponent } from './components/forgot-password-modal/forgot-password-modal.component';
import { InscriptionClientComponent } from './containers/inscription-client/inscription-client.component';
import { RouterLink } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { GenericUserListComponent } from './components/generic-user-list/generic-user-list.component';
import { InscriptionCommercialComponent } from './containers/inscription-commercial/inscription-commercial.component';
import { StartpageContainerComponent } from './components/startpage-container/startpage-container.component';

@NgModule({
  declarations: [
    SimpleOkModalComponent,
    ConfigurationItemComponent,
    TableautinComponent,
    LoginComponent,
    ForgotPasswordModalComponent,
    InscriptionClientComponent,
    GenericUserListComponent,
    InscriptionCommercialComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterLink,
    ToastModule,
    TableModule,
    InputTextModule,
    StartpageContainerComponent,
  ],
  exports: [
    SimpleOkModalComponent,
    ConfigurationItemComponent,
    TableautinComponent,
    LoginComponent,
    InscriptionClientComponent,
    GenericUserListComponent,
    InscriptionCommercialComponent,
  ],
})
export class SharedModule {}
