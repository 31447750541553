import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '@depagne/types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-project-modal',
  templateUrl: './add-project-modal.component.html',
  styleUrls: ['./add-project-modal.component.scss'],
})
export class AddProjectModalComponent implements OnInit {
  projectForm = this.fb.group({
    name: ['', Validators.required],
    description: [''],
    client: ['', Validators.required],
    company: ['', Validators.required],
    reduction: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
  });

  constructor(
    public dialogRef: MatDialogRef<AddProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project;
    },
    private fb: FormBuilder,
  ) {
    const { name, description, client, company, reduction } = data.project;

    console.log(data.project);

    this.projectForm.patchValue({
      name,
      description,
      client,
      company,
      reduction,
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    console.log(this.projectForm.value);
    this.dialogRef.close({
      ...this.data.project,
      ...this.projectForm.value,
    });
  }
}
