import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugComponent } from './containers/debug/debug.component';
import { RouterModule, Routes } from '@angular/router';
import { HasUserGuard } from '../../shared/guards/has-user.guard';

const routes: Routes = [
  {
    canActivate: [HasUserGuard],
    data: {
      roles: ['user', 'commercial', 'admin'],
    },
    path: 'debug',
    component: DebugComponent,
  },
];

@NgModule({
  declarations: [DebugComponent],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DebugModule {}
