import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-read-cgu-modal',
  templateUrl: './read-c-g-u-modal.component.html',
  styleUrls: ['./read-c-g-u-modal.component.scss'],
})
export class ReadCGUModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReadCGUModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
    },
  ) {}

  ngOnInit(): void {}

  ok() {
    this.dialogRef.close({ ok: true });
  }
}
