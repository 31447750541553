<div class="modal">
  <div class="modal-body">
    <app-configuration-item
      [ngClass]="{hidden: configItem.hidden}"
      *ngFor="let configItem of configList"
      [item]="configItem"
    ></app-configuration-item>
  </div>
  <div class="commentaire-config" *ngIf="data.config.details.commentaire">
    <h3>commentaire de la configuration</h3>
    <p>{{ data.config.details.commentaire }}</p>
  </div>
  <div class="table">
    <app-tableautin [config]="config"></app-tableautin>
  </div>
  <div class="nomenclature">
    <thead>
      <tr>
        <th class="t1">reference</th>
        <th class="t2">designation</th>
        <th class="t3">quantité</th>
        <th class="t4" *ngIf="showPrice">prix</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data.config.nomenclature?.items">
        <td class="t1">{{item.reference}}</td>
        <td class="t2">{{item.designation}}</td>
        <td class="t3">{{item.quantity}}</td>
        <td class="t4" *ngIf="showPrice">{{item.price}} <span>€</span><span *ngIf="item.surdevis">(sur devis)</span></td>
      </tr>
    </tbody>
  </div>
  <div *ngIf="showPrice" class="price">
    <span>Prix total :</span>{{data.config.nomenclature?.totalPrice | number:'0.2-2'}}<span>€</span>
  </div>
  <div class="modal-footer">
    <button (click)="close()">Fermer</button>
  </div>
</div>
