import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Depart,
  DisjoncteurBranchement,
  Enveloppe,
  InterDiff,
  Kit,
  KitBornier,
  KitWithModules,
  MaterielGestion,
  Parafoudre,
  PlatineCommande,
  PlatineComptage,
  Tableautin,
} from '@depagne/types';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { Reference, TarifPublic } from '@depagne/types';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public loaded$ = new ReplaySubject();
  public disjoncteurData!: DisjoncteurBranchement[];
  public parafoudreData!: Parafoudre[];
  public materielGestionData!: MaterielGestion[];
  public kitEclairageData!: KitWithModules[];
  public kitPriseData!: KitWithModules[];
  public kitBarretteData!: Kit[];
  public tableautinData!: Tableautin[];
  public enveloppeData!: Enveloppe[];
  public platineCommandeData!: PlatineCommande[];
  public platineComptageData!: PlatineComptage[];
  public departData!: Depart[];
  public interDiffData!: InterDiff[];
  public kitBornierData!: KitBornier[];
  public tarifPublicData!: TarifPublic[];
  constructor(private http: HttpClient) {}

  async loadAllData() {
    await this.loadDisjoncteurData();
    await this.loadParafoudreData();
    await this.loadMaterielGestion();
    await this.loadKitEclairage();
    await this.loadKitPrise();
    await this.loadKitBarrette();
    await this.loadTableautin();
    await this.loadEnveloppe();
    await this.loadPlatineComptage();
    await this.loadPlatineCommande();
    await this.loadDepart();
    await this.loadInterDiff();
    await this.loadKitBornier();
    await this.loadTarifPublic();

    this.loaded$.next(true);
  }

  private async loadDisjoncteurData() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableDisjoncteurBranchement.json'),
    )) as unknown as DisjoncteurBranchement[];

    this.disjoncteurData = this.addIdAndName(data);
  }

  private async loadParafoudreData() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableParafoudre.json?' + new Date().toISOString()),
    )) as unknown as Parafoudre[];

    this.parafoudreData = this.addIdAndName(data);
  }

  private async loadMaterielGestion() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableGestion.json?' + new Date().toISOString()),
    )) as unknown as MaterielGestion[];

    this.materielGestionData = this.addIdAndName(data);
  }

  private async loadKitEclairage() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableKitEclairage.json?' + new Date().toISOString()),
    )) as unknown as KitWithModules[];

    this.kitEclairageData = [...this.addIdAndName(data), { id: 'aucune', name: 'Aucune' }];
  }

  private async loadKitPrise() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableKitPrise.json?' + new Date().toISOString()),
    )) as unknown as KitWithModules[];

    this.kitPriseData = [...this.addIdAndName(data), { id: 'aucune', name: 'Aucune' }];
  }

  private async loadKitBarrette() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableKitBarrette.json?' + new Date().toISOString()),
    )) as unknown as Kit[];

    this.kitBarretteData = [...this.addIdAndName(data), { id: 'aucune', name: 'Aucune' }];
  }

  private async loadTableautin() {
    const data = (await firstValueFrom(this.http.get('/assets/data/TableTableautin.json?' + new Date().toISOString()))) as unknown as Kit[];

    this.tableautinData = this.addIdAndName(data);
  }

  private async loadEnveloppe() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableEnveloppe.json?' + new Date().toISOString()),
    )) as unknown as Enveloppe[];

    this.enveloppeData = this.addIdAndName(data);
  }

  private async loadPlatineCommande() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TablePlatineCommande.json?' + new Date().toISOString()),
    )) as unknown as PlatineCommande[];

    this.platineCommandeData = this.addIdAndName(data);
  }

  private async loadPlatineComptage() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TablePlatineComptage.json?' + new Date().toISOString()),
    )) as unknown as PlatineComptage[];

    this.platineComptageData = this.addIdAndName(data);
  }

  private async loadDepart() {
    const data = (await firstValueFrom(this.http.get('/assets/data/TableDeparts.json?' + new Date().toISOString()))) as unknown as Depart[];

    this.departData = this.addIdAndName(data);
  }

  private async loadInterDiff() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableInterDiff.json?' + new Date().toISOString()),
    )) as unknown as InterDiff[];

    this.interDiffData = this.addIdAndName(data);
  }

  private async loadKitBornier() {
    const data = (await firstValueFrom(
      this.http.get('/assets/data/TableKitBorniers.json?' + new Date().toISOString()),
    )) as unknown as KitBornier[];

    this.kitBornierData = this.addIdAndName(data);
  }

  private async loadTarifPublic() {
    const data = (await firstValueFrom(this.http.get(environment.api + '/admin/active-tarif-data'))) as unknown as TarifPublic[];

    this.tarifPublicData = this.addIdAndName(data);
  }

  private addIdAndName(data: any) {
    return data.map((element: any) => ({
      id: String(element.Reference || element.Ref1), // sometimes ids in the excel are number types
      name: element.Designation,
      ...element,
    }));
  }
}
