import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { switchMap } from 'rxjs';
import { User } from '@depagne/types';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent implements OnInit {
  forgotForm = this.fb.group({
    username: ['', [Validators.required, Validators.email]],
  });
  error: string | null = null;
  public message = '';
  constructor(public dialogRef: MatDialogRef<ForgotPasswordModalComponent>, private userService: UserService, private fb: FormBuilder) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const { username } = this.forgotForm.value;
    this.message = 'En attend...';
    this.userService.forgotPassword(username as string).subscribe({
      next: (data) => {
        this.error = null;
        this.message = 'Un e-mail vous a été envoyé.';
      },
      error: async (error) => {
        // ToDo show toast depending on error, login or getting user data
        console.log('error:', error);
        this.message = '';
        this.error = error?.error?.message || error;
      },
    });
  }
}
