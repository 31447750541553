import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationItem } from '@depagne/types';

@Component({
  selector: 'app-configuration-item',
  templateUrl: './configuration-item.component.html',
  styleUrls: ['./configuration-item.component.scss'],
})
export class ConfigurationItemComponent {
  @Input() item!: ConfigurationItem;

  constructor() {}
}
