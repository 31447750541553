import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, pipe, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { AdditionalInvoiceLine, CommercialUser, Configuration, FullUser, Project, User, UserRole } from '@depagne/types';
import { MatDialog } from '@angular/material/dialog';
import { AddProjectModalComponent } from '../components/add-project-modal/add-project-modal.component';
import { VisualizeConfigModalComponent } from '../components/visualize-config-modal/visualize-config-modal.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AddInvoiceLineModalComponent } from '../components/add-invoice-line-modal/add-invoice-line-modal.component';
import { AddClientProjectModalComponent } from '../components/add-client-project-modal/add-client-project-modal.component';
import { UserService } from '../../shared/services/user.service';
import { SimpleOkModalComponent } from '../../shared/components/simple-ok-modal/simple-ok-modal.component';
import { SendDevisModalComponent } from '../components/send-devis-modal/send-devis-modal.component';
import { ToastService } from '../../shared/services/toast.service';
import { ModifyProjectDeliveryInfoModalComponent } from '../components/modify-project-delivery-info/modify-project-delivery-info-modal.component';
import { DataService } from '../../configurator/services/data.service';
import { filter } from 'rxjs/operators';
import { getConfigItem } from '@depagne/utils';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public projectsSubject$ = new BehaviorSubject<Project[]>([]);
  public projects$: Observable<Project[]> = this.projectsSubject$.pipe(
    withLatestFrom(this.userService.user$ as Observable<User>),
    map(([projects, user]) => {
      return projects.map((project) => {
        project.showPrice = UserService.isCommercial(user) || project.status === 'invoiceSent';

        project.isFrozen = true;

        if (UserService.isClient(user) && project.status === 'draft') {
          project.isFrozen = false;
        }

        if (UserService.isCommercial(user) && (project.status === 'draftCommercial' || project.status === 'sentToDepagne')) {
          project.isFrozen = false;
        }

        project.showCreateDevis = false;

        if (
          (UserService.isCommercial(user) && project.status === 'draftCommercial') ||
          project.status === 'invoiceSent' ||
          project.status === 'invoiceCommercial'
        ) {
          project.showCreateDevis = true;
        }

        return project;
      });
    }),
    shareReplay(1),
  );
  public loaded$ = new BehaviorSubject(false);

  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private userService: UserService,
    private toastService: ToastService,
    private dataService: DataService,
  ) {}

  addProject(user: FullUser) {
    let emptyProject: Omit<Project, 'id' | 'lastModified'> = {
      name: '',
      description: '',
      client: '',
      company: '',
      dates: {},
      status: 'draft',
      configurations: [],
      additions: [],
      reduction: 0,
      price: 0.0,
      nonReducedPrice: 0.0,
      isClientProject: false,
      delivery: {
        delay: '',
        priceDelivery: 0,
        priceAdministratif: 0,
      },
      lastTouchedUserId: user.id,
    };

    let dialogRef;
    if (UserService.isClient(user)) {
      emptyProject = {
        ...emptyProject,
        dates: {
          draft: new Date().toString(),
        },
        status: 'draft',
        isClientProject: true,
        client: user.name,
        company: user.company,
        reduction: user.reduction,
      };

      dialogRef = this.dialog.open(AddClientProjectModalComponent, {
        data: { project: emptyProject },
      });
    } else {
      emptyProject = {
        ...emptyProject,
        dates: {
          draftCommercial: new Date().toString(),
        },
        status: 'draftCommercial',
      };

      dialogRef = this.dialog.open(AddProjectModalComponent, {
        data: { project: emptyProject },
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({ result });

        this.http.post(environment.api + '/project', result).subscribe((response: any) => {
          console.log(response);
          delete response.user;
          this.projects$.pipe(take(1)).subscribe((projects) => this.projectsSubject$.next([response, ...projects]));
        });
      }
    });
  }

  modifyProject(project: Project, user: User) {
    let dialogRef;
    if (UserService.isClient(user)) {
      dialogRef = this.dialog.open(AddClientProjectModalComponent, {
        data: { project, modify: true },
      });
    } else {
      dialogRef = this.dialog.open(AddProjectModalComponent, {
        data: { project, modify: true },
      });
    }

    dialogRef.afterClosed().subscribe((updatedProject: Project | null) => {
      if (updatedProject) {
        console.log({ result: updatedProject });
        this.updateProjectOnServer(updatedProject);
      }
    });
  }

  modifyDelivery(project: Project) {
    const dialogRef = this.dialog.open(ModifyProjectDeliveryInfoModalComponent, {
      data: { project },
    });

    dialogRef.afterClosed().subscribe((updatedProject: Project | null) => {
      if (updatedProject) {
        console.log({ result: updatedProject });
        this.updateProjectOnServer(updatedProject);
      }
    });
  }

  public updateProjectOnServer(updatedProject: Project) {
    this.http
      .patch<Project>(`${environment.api}/project/${updatedProject.id}`, updatedProject)
      .pipe(withLatestFrom(this.projects$))
      .subscribe(([projectFromServer, projects]) =>
        this.projectsSubject$.next([
          ...projects.map((project) => {
            if (project.id === updatedProject.id) {
              return projectFromServer;
            }

            return project;
          }),
        ]),
      );
  }

  deleteProject(project: Project) {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: { text: 'Attention, vous allez supprimer votre projet, êtes-vous sûr ?' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
        this.http
          .delete(`${environment.api}/project/${project.id}`)
          .pipe(withLatestFrom(this.projects$))
          .subscribe(([response, projects]) =>
            this.projectsSubject$.next([...projects.filter((tmpProject) => tmpProject.id !== project.id)]),
          );
      }
    });
  }

  getConfiguration(projectId: string, configId: string): Observable<Configuration> {
    return this.projects$.pipe(take(1)).pipe(
      map((projects) => {
        return projects
          .filter((project) => project.id === projectId)[0]
          ?.configurations.filter((configuration) => configuration.id === configId)[0];
      }),
    );
  }

  addConfiguration(projectId: string, config: Configuration) {
    return this.getProject(projectId).pipe(
      switchMap((project) => {
        project.configurations = [...project.configurations, config];
        return this.http.patch(`${environment.api}/project/${projectId}`, project);
      }),
      this.refreshProjectPipe(projectId),
    );
  }

  updateConfiguration(projectId: string, config: Configuration) {
    return this.getProject(projectId).pipe(
      switchMap((project) => {
        project.configurations = project.configurations.map((tmpConfiguration) => {
          if (tmpConfiguration.id === config.id) {
            return config;
          }

          return tmpConfiguration;
        });
        return this.http.patch(`${environment.api}/project/${projectId}`, project);
      }),
      this.refreshProjectPipe(projectId),
    );
  }

  deleteConfiguration(projectId: string, config: Configuration) {
    return this.getProject(projectId).pipe(
      switchMap((project) => {
        project.configurations = project.configurations.filter((tmpConfiguration) => tmpConfiguration.id !== config.id);

        return this.http.patch(`${environment.api}/project/${projectId}`, project);
      }),
      this.refreshProjectPipe(projectId),
    );
  }

  visualizeConfiguration(config: Configuration, user: User, showPrice: boolean) {
    const dialogRef = this.dialog.open(VisualizeConfigModalComponent, {
      data: { config, showPrice },
      panelClass: 'custom-dialog',
    });
  }

  getProject(id: string): Observable<Project> {
    return this.projects$.pipe(
      filter((project) => {
        return project.length > 0;
      }),
      take(1),
      map((projects) => {
        return projects.filter((project) => id === project.id)[0];
      }),
    );
  }

  loadProjectsForUser() {
    this.http.get(environment.api + '/project').subscribe({
      next: (response) => {
        console.log(response);
        this.projectsSubject$.next(response as Project[]);
        this.loaded$.next(true);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  addInvoiceLine(project: Project, editPrice: boolean, config?: Configuration) {
    const projectId = project.id;
    const emptyLine: AdditionalInvoiceLine = {
      name: '',
      price: 0.0,
      reducedPrice: 0.0,
      file: '',
      quantity: 1,
    };

    const dialogRef = this.dialog.open(AddInvoiceLineModalComponent, {
      data: { line: emptyLine, editPrice },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({ result });

        if (config) {
          if (!config.additions) {
            config.additions = [];
          }

          config.additions = [...config.additions, result];
          this.updateConfiguration(project.id, config).subscribe();
          return;
        }

        project.additions = [...project.additions, result];
        this.http.patch(`${environment.api}/project/${project.id}`, project).pipe(this.refreshProjectPipe(projectId)).subscribe();
      }
    });
  }

  modifyInvoiceLine(project: Project, index: number, editPrice: boolean, config?: Configuration) {
    const projectId = project.id;

    let data;

    if (config) {
      data = { line: config.additions[index], modify: true, editPrice };
    } else {
      data = { line: project.additions[index], modify: true, editPrice };
    }

    const dialogRef = this.dialog.open(AddInvoiceLineModalComponent, {
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log({ result });

        if (config) {
          config.additions[index] = result;
          this.updateConfiguration(project.id, config).subscribe();
          return;
        }

        project.additions[index] = result;
        this.http.patch(`${environment.api}/project/${project.id}`, project).pipe(this.refreshProjectPipe(projectId)).subscribe();
      }
    });
  }

  deleteInvoiceLine(project: Project, index: number, config?: Configuration) {
    console.log(index);
    const projectId = project.id;

    if (config) {
      config.additions.splice(index, 1);
      this.updateConfiguration(project.id, config).subscribe();
      return;
    }

    project.additions.splice(index, 1);

    this.http.patch(`${environment.api}/project/${project.id}`, project).pipe(this.refreshProjectPipe(projectId)).subscribe();
  }

  updateInvoiceLine(project: Project, index: number, invoice: AdditionalInvoiceLine, config?: Configuration) {
    const projectId = project.id;

    if (config) {
      config.additions[index] = invoice;
      this.updateConfiguration(project.id, config).subscribe();
      return;
    }

    project.additions[index] = invoice;
    this.http.patch(`${environment.api}/project/${project.id}`, project).pipe(this.refreshProjectPipe(projectId)).subscribe();
  }

  downloadInvoiceFile(filename: string) {
    this.http
      .get(`${environment.api}/project/file/download`, {
        responseType: 'arraybuffer',
        params: {
          filename,
        },
      })
      .subscribe((response) => this.downLoadFile(response, 'application/pdf'));
  }

  downLoadFile(data: any, type: string, project?: Project) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);

    if (!project) {
      let pwa = window.open(url);
      if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert("Merci d'enlever votre blockeur de pop-up");
      }
    } else {
      const fileLink = document.createElement('a');
      fileLink.href = url;
      // it forces the name of the downloaded file
      fileLink.download = project.id + '-' + project.company + '-' + project.name + '.pdf';
      fileLink.click();
    }
  }

  async sendToDepagne(project: Project) {
    const prompt = await this.promptSendToDepagne();

    if (!prompt) {
      return;
    }

    const projectId = project.id;

    this.http
      .post(`${environment.api}/project/transfer-to-commercial/${project.id}`, {})
      .pipe(this.refreshProjectPipe(projectId))
      .subscribe((response: any) => {
        console.log(response);
        this.showSendToDepagneSuccess();
      });
  }

  async promptSendToDepagne() {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: { text: 'Est-ce que vous voulez envoyer votre projet au service commercial Depagne ?' },
    });

    const result = await dialogRef.afterClosed().toPromise();

    if (result.ok) {
      return true;
    }

    return false;
  }

  showSendToDepagneSuccess() {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: {
        text: 'Votre projet a été transféré à votre commercial de secteur. Nous vous répondrons dans les meilleurs délais.',
        prompt: true,
        image: 'modal/modal-projet.png',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
      }
    });
  }

  prepareDevisBeforeSendToClient(project: Project) {
    const dialogRef = this.dialog.open(SendDevisModalComponent, {
      data: { project },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok && result.project) {
        this.http
          .patch(`${environment.api}/project/${project.id}`, result.project)
          .pipe(this.refreshProjectPipe(project.id))
          .subscribe((response) => {
            console.log(response);
            this.sendToClient(result.project);
          });
      }
    });
  }

  sendToClient(project: Project) {
    const projectId = project.id;

    this.http
      .post(`${environment.api}/project/transfer-to-client/${project.id}`, {})
      .pipe(this.refreshProjectPipe(projectId))
      .subscribe((response: any) => {
        console.log(response);
        this.toastService.showToast('success', 'Le devis a bien été envoyé');
      });
  }

  private refreshProjectPipe(projectId: string) {
    return pipe(
      switchMap(() => {
        return this.http.get(`${environment.api}/project/${projectId}`) as unknown as Observable<Project>;
      }),
      withLatestFrom(this.projects$),
      map(([changedProject, projects]) => {
        projects = projects.map((project) => {
          if (project.id === projectId) {
            project = changedProject;
          }

          return project;
        });
        this.projectsSubject$.next(projects);

        return projects;
      }),
    );
  }

  copyProject(project: Project, user: FullUser) {
    this.http.post(`${environment.api}/project/copy/${project.id}`, {}).subscribe((response: any) => {
      console.log(response);
      this.toastService.showToast('success', 'Le projet a bien été dupliqué');
      this.loadProjectsForUser();
    });
  }

  abortSendToDepagne(project: Project) {
    this.http.post(`${environment.api}/project/abort/${project.id}`, {}).subscribe((response: any) => {
      console.log(response);
      this.loadProjectsForUser();
    });
  }

  getAllProjects() {
    return this.http.get<Project[]>(environment.api + '/project/all');
  }

  transferProjectToCommercial(project: Project, commercialId: number) {
    return this.http.post(`${environment.api}/project/transfer/${project.id}`, { commercialId: commercialId });
  }

  public getCommercialForProject(projectId: string) {
    return this.http.get<CommercialUser>(`${environment.api}/project/commercial/${projectId}`);
  }

  static setDevisSurAlertOnConfigs(project: Project) {
    project.configurations = project.configurations.map((config) => {
      const supportTableautin = getConfigItem(config.details.configurationItems, 'supportTableautin');

      if (supportTableautin.value.id === 'enveloppe') {
        const materielEnveloppe = getConfigItem(config.details.configurationItems, 'materielEnveloppe');
        config.image = materielEnveloppe.value?.VISUELS;
      } else if (supportTableautin.value.id === 'platine') {
        // const materielPlatineCommande = getConfigItem(config.details.configurationItems, 'materielPlatineCommande');
        // config.image = materielPlatineCommande.value?.id;
      }

      if (config.nomenclature && config.nomenclature.items[0].surdevis) {
        config.surdevisAlert = true;
      } else {
        config.surdevisAlert = false;
      }

      return config;
    });

    return project;
  }
}
