<div class="modal">
  <div class="modal-body">
    <p>
      Mot de passe oublié ?<br />
      Merci d'entrer votre e-mail pour vous envoyer un lien de réinitialisation.
    </p>
    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
      <div class="input-group" *ngIf="!message">
        <label for="name">Email </label>
        <input id="name" type="text" formControlName="username" />
      </div>
      <p *ngIf="message">{{message}}</p>
      <p *ngIf="error" class="error">{{error}}</p>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="cancel()">Retour</button>
    <button *ngIf="!message" class="validation-button" (click)="onSubmit()" [disabled]="!forgotForm.valid">Envoyer</button>
  </div>
</div>
