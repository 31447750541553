<div class="modal">
  <div class="modal-header">
    <h1>Nouveau projet</h1>
  </div>
  <form [formGroup]="deliveryForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p class="mini-label">Saisie obligatoire *</p>
      <div class="input-group">
        <label for="delay">délais de livraison en jours ouvres*</label>
        <input id="delay" type="text" formControlName="delay" />
      </div>
      <div class="input-group">
        <label for="priceDelivery">frais de port*</label>
        <input id="priceDelivery" type="number" min="0" formControlName="priceDelivery" />
      </div>
      <div class="input-group">
        <label for="priceAdministratif">frais administratifs*</label>
        <input id="priceAdministratif" type="number" min="0" formControlName="priceAdministratif" />
      </div>
      <div class="modal-footer">
        <button mat-dialog-close>annuler</button>
        <button class="validation-button" type="submit" [disabled]="!deliveryForm.valid">valider</button>
      </div>
    </div>
  </form>
</div>
