import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { UserService } from '../services/user.service';
import { first, map } from 'rxjs/operators';
import { DataService } from '../../configurator/services/data.service';
import { ProjectService } from '../../dashboard/services/project.service';
import { FullUser, User } from '@depagne/types';

@Injectable({
  providedIn: 'root',
})
export class HasUserGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private dataService: DataService,
    private projectService: ProjectService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.user$.pipe(
      first(),
      switchMap((user) => {
        if (!user) {
          this.router.navigate(['/login']);
          return of(false);
        } else {
          const roles = next.data['roles'];
          console.log('local user', user);

          if (this.userService.firstRefreshMade) {
            return of(this.hastRole(user, roles));
          }

          return this.userService.refreshUserData().pipe(
            map(
              (response: any) => {
                console.log('refresh user', response);
                this.userService.storeUser(response);
                this.dataService.loadAllData();
                this.userService.checkCGU(response as User);
                this.projectService.loadProjectsForUser();
                this.userService.firstRefreshMade = true;
                return this.hastRole(user, roles);
              },
              (error: any) => {
                console.error(`couldn't update user data`, error);
                return false;
              },
            ),
          );
        }
      }),
    );
  }

  hastRole(user: FullUser, roles: string[]): boolean {
    if (roles.includes(user.role)) {
      return true;
    }

    if (user.role === 'visualadmin') {
      this.router.navigate(['/visual-admin']);
    }

    return false;
  }
}
