import { Injectable } from '@angular/core';
import { ProjectService } from './project.service';
import { HttpClient } from '@angular/common/http';

const PROJECTSTORAGE = 'depagne_projects';

@Injectable({
  providedIn: 'root',
})
export class DataStorageService {
  constructor(private projectService: ProjectService) {
    // this.loadData();
    // this.listenAndSaveData();
  }

  private loadData() {
    const projects = localStorage.getItem(PROJECTSTORAGE);
    if (projects) {
      this.projectService.projectsSubject$.next(JSON.parse(projects));
    }
  }

  private listenAndSaveData() {
    this.projectService.projects$.subscribe((projects) => {
      localStorage.setItem(PROJECTSTORAGE, JSON.stringify(projects));
    });
  }
}
