import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../configurator/services/data.service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css'],
})
export class DebugComponent implements OnInit {
  constructor(public data: DataService) {}

  ngOnInit(): void {}
}
