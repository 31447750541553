import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationElectronicDetails } from '@depagne/types';
import { TableautinService } from '../../../configurator/services/tableautin.service';

@Component({
  selector: 'app-tableautin',
  templateUrl: './tableautin.component.html',
  styleUrls: ['./tableautin.component.scss'],
})
export class TableautinComponent implements OnInit, OnChanges {
  @Input() config!: ConfigurationElectronicDetails;
  tableautin: Array<{ nbModules: number; name: string }[]> | undefined;

  constructor(private tableautinService: TableautinService) {
    if (this.config?.selectedTableautin) {
      this.tableautin = this.tableautinService.validateTableautin(this.config.selectedTableautin, this.config).tableatinAsObject;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['config']) {
      if (this.config.selectedTableautin) {
        this.tableautin = this.tableautinService.validateTableautin(this.config.selectedTableautin, this.config).tableatinAsObject;
      }
    }
  }

  ngOnInit(): void {}
}
