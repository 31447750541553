import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '@depagne/types';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-project-modal',
  templateUrl: './modify-project-delivery-info-modal.component.html',
  styleUrls: ['./modify-project-delivery-info-modal.component.scss'],
})
export class ModifyProjectDeliveryInfoModalComponent implements OnInit {
  deliveryForm = this.fb.group({
    delay: ['', Validators.required],
    priceDelivery: [0, Validators.required],
    priceAdministratif: [0, Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<ModifyProjectDeliveryInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      project: Project;
    },
    private fb: FormBuilder,
  ) {
    const { delay, priceDelivery, priceAdministratif } = data.project.delivery;

    console.log(data.project);

    this.deliveryForm.patchValue({
      delay,
      priceDelivery,
      priceAdministratif,
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    console.log(this.deliveryForm.value);
    this.dialogRef.close({
      ...this.data.project,
      delivery: {
        ...this.deliveryForm.value,
      },
    });
  }
}
