import { Project } from '@depagne/types';

export function calculateProjectPrice(project: Project) {
  if (!project.delivery) {
    project.delivery = {
      delay: '',
      priceDelivery: 0,
      priceAdministratif: 0,
    };
  }

  project.configurations = project.configurations.map((config) => {
    config.quantity = config.quantity ? config.quantity : 1;

    if (config.additions) {
      config.additions = config.additions.map((addition) => {
        addition.quantity = addition.quantity ? addition.quantity : 1;
        addition.reducedPrice = addition.price * Math.abs(project.reduction / 100 - 1);

        return addition;
      });
    }

    return config;
  });

  project.additions = project.additions.map((addition) => {
    addition.quantity = addition.quantity ? addition.quantity : 1;
    addition.reducedPrice = addition.price * Math.abs(project.reduction / 100 - 1);

    return addition;
  });

  project.price =
    project.configurations.reduce((total, config) => {
      let additionsPrice = 0;
      let totalConfigPrice = 0;

      if (config.additions) {
        additionsPrice = config.additions.reduce(
          (total, addition) => (addition.reducedPrice ? total + addition.reducedPrice * addition.quantity : total),
          0,
        );
      }

      if (config.nomenclature?.totalPrice) {
        if (!config.nomenclature.reducedPrice) {
          config.nomenclature.reducedPrice = config.nomenclature.totalPrice;
        } else {
          config.nomenclature.reducedPrice = config.nomenclature.totalPrice * Math.abs(project.reduction / 100 - 1);
        }
        totalConfigPrice = config.nomenclature.reducedPrice * config.quantity;
      }

      return total + totalConfigPrice + additionsPrice;
    }, 0) +
    project.additions.reduce((total, addition) => (addition.reducedPrice ? total + addition.reducedPrice * addition.quantity : total), 0) +
    project.delivery.priceDelivery +
    project.delivery.priceAdministratif;

  return project;
}

export function calculateNonReducedProjectPrice(project: Project) {
  project = calculateProjectPrice(project);

  project.nonReducedPrice =
    project.configurations.reduce((total, config) => {
      let additionsPrice = 0;
      let totalConfigPrice = 0;

      if (config.additions) {
        additionsPrice = config.additions.reduce(
          (total, addition) => (addition.reducedPrice ? total + addition.reducedPrice * addition.quantity : total),
          0,
        );
      }

      if (config.nomenclature?.totalPrice) {
        totalConfigPrice = config.nomenclature.totalPrice * config.quantity;
      }

      return total + totalConfigPrice + additionsPrice;
    }, 0) +
    project.additions.reduce((total, addition) => (addition.price ? total + addition.price * addition.quantity : total), 0) +
    project.delivery.priceDelivery +
    project.delivery.priceAdministratif;

  return project;
}
