import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ReadCGUModalComponent } from '../read-cgu-modal/read-c-g-u-modal.component';

@Component({
  selector: 'app-read-cgu-modal',
  templateUrl: './accept-c-g-u-modal.component.html',
  styleUrls: ['./accept-c-g-u-modal.component.scss'],
})
export class AcceptCGUModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AcceptCGUModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
    },
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {}

  ok() {
    this.dialogRef.close({ ok: true });
  }

  readCGU(client: 'client' | 'commercial') {
    const dialogRef = this.dialog.open(ReadCGUModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
      }
    });
  }
}
