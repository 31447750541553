<div class="modal">
  <div class="modal-body">
    <h1>Conditions générales d'utilisation</h1>
    <div class="cgu-body">
      <h2>PREAMBULE</h2>
      <p>
        1. DEPAGNE a conçu, réalisé et exploite, directement et par des agents commerciaux, un service innovant et original (ci-après
        dénommé le service « TERRA ») de configuration et de chiffrage en ligne d’installations de contrôle et commande d’éclairage public
        dans le respect des normes NF C 17-2000 et spécification Enedis G.7.4-01.
        <br />
        2. Ce service à forte valeur ajoutée, directement accessible depuis un logiciel DEPAGNE, permet de créer ses propres projets, de
        configurer l’équipement et d’obtenir un devis émis par DEPAGNE pour la fourniture et la pose de ses équipements spécialement
        configurés pour les besoins exprimés par l’Utilisateur.
        <br />
        3. En accédant et en utilisant le service « TERRA », l’Utilisateur accepte sans aucune réserve les présentes conditions générales
        d’utilisation qui définissent ses droits et obligations.<br />
        6. DEPAGNE recommande d’imprimer les présentes conditions générales d’utilisation avant d’en accepter les termes et conditions.
      </p>

      <h2>1. DEFINITIONS</h2>
      <p>
        1. Dans la suite des présentes conditions générales d’utilisation, chacune des expressions mentionnées ci-dessous aura la
        signification donnée dans sa définition, à savoir :<br />
        - «identifiant » : adresse e-mail de l’Utilisateur renseignée par l’Utilisateur lors de son inscription. L’Utilisateur est seul
        responsable de la confidentialité, de la garde et de l’utilisation de son identifiant ;<br />
        - «mot de passe » : code d’authentification permettant à l’Utilisateur d’accéder au service « TERRA» choisi par l’Utilisateur lors
        de son inscription. L’utilisation du mot de passe est nécessaire pour l’authentification de l’Utilisateur. Ce dernier est seul
        responsable de la confidentialité, de la garde et de l’utilisation de son mot de passe ;<br />
      </p>

      <h2>2. ENTREE EN VIGUEUR</h2>
      <p>
        1. Les présentes conditions générales entrent en vigueur à compter de la date de souscription par l’Utilisateur au service « TERRA
        », cette souscription et son accord aux présentes conditions générales étant marqués par un « clic » sur l’icône « INSCRIPTION »,
        tel que prévu dans le formulaire d’inscription en ligne. Cette inscription n’entre en vigueur qu’après validation par DEPAGNE.
      </p>

      <h2>3. COLLECTE DES DONNEES</h2>
      <p>
        Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. En vertu de la loi
        Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et
        d'opposition de ses données personnelles. L'Utilisateur exerce ce droit par mail à l'adresse email marketing&#64;depagne.fr.
      </p>

      <h2>4. MISE EN GARDE PREALABLE ET COLLABORATION</h2>
      <p>
        1. Il appartient à l’Utilisateur de s’assurer notamment : - de disposer de la compétence et des moyens nécessaires pour accéder et
        utiliser le service « TERRA » ; - du respect par toute personne travaillant pour son compte des présentes conditions générales
        d’utilisation, notamment en termes de confidentialité. <br />2. En tout état de cause, l’utilisation du service « TERRA » par
        l’Utilisateur s’effectue sous son seul contrôle et sa responsabilité. <br />3. L’Utilisateur s’interdit de transmettre, dans le
        cadre de l’utilisation du service « TERRA » toute donnée prohibée, illicite, illégale ou susceptible : - de constituer un abus de
        droit ; - d’être contraire à l’ordre public et/ou aux bonnes mœurs ; - d’être utilisée pour exercer une menace ou une pression de
        quelque nature, forme ou objet que ce soit ; - d’être en contravention ou en contradiction avec tout droit de propriété
        intellectuelle ou industrielle. <br />4. DEPAGNE s’interdit d’utiliser des données communiquées par l’Utilisateur par
        l’intermédiaire du service « TERRA » dans le cadre de cession de fichiers à des tiers mais se réserve la possibilité de les utiliser
        aux fins de communications commerciales et/ou promotionnelles. <br />5. L’Utilisateur s’engage par ailleurs à faire ses meilleurs
        efforts pour apporter à DEPAGNE l’assistance susceptible de faciliter la réalisation du service « TERRA » et à communiquer à DEPAGNE
        toutes les informations et/ou documents nécessaires à la réalisation dudit service.
      </p>

      <h2>5. ACCES AU SERVICE « TERRA »</h2>
      <p>
        1. L’Utilisateur peut normalement accéder au service 24/24 heures et 7/7 jours et ce, toute l’année, sans que cela constitue une
        quelconque obligation de performance de la part de DEPAGNE.<br />
        2. DEPAGNE se réserve par ailleurs le droit, sans préavis, de fermer temporairement l’accès à tout ou partie du service « TERRA »
        pour y effectuer une mise à jour, des modifications, ou tout autre changement qu’elle jugera utile. <br />3. DEPAGNE n’est pas
        responsable des dommages de toute nature qui peuvent résulter de ces changements et/ou d’une indisponibilité temporaire ou totale de
        tout ou partie du service « TERRA ».
      </p>

      <h2>6. GRATUITE DU SERVICE</h2>
      <p>
        L’utilisation par l’Utilisateur du service « TERRA » est entièrement gratuite et ne peut donner lieu à une quelconque facturation
        par DEPAGNE.
      </p>

      <h2>7. MOYENS D’ACCES AU SERVICE « TERRA »</h2>
      <p>
        1. Afin de pouvoir accéder au service « TERRA », l’Utilisateur doit disposer d’un micro-ordinateur et de moyens de
        télécommunications lui permettant de se connecter à Internet. <br />2. L’Utilisateur doit également disposer d’une adresse e-mail.
        <br />3. Les coûts de connexions et de télécommunications restent à la charge de l’Utilisateur.
      </p>

      <h2>8. PROPRIETE ET DROIT D’UTILISATION DU SERVICE « TERRA »</h2>
      <p>
        1. Le service « TERRA » est la propriété pleine et entière de DEPAGNE.<br />
        2. DEPAGNE est et demeure titulaire de l’ensemble des droits de propriété intellectuelle relatifs au service « TERRA » et notamment
        au logiciel permettant d’y accéder. <br />3. Le droit d’utiliser le service « TERRA », qui est concédé à l’Utilisateur par DEPAGNE,
        est non-exclusif, personnel, non-transmissible et pour une utilisation strictement professionnelle qui doit être conforme aux
        conditions définies aux présentes.<br />
        4. Ce droit d’utiliser le service « TERRA » ne confère à l’Utilisateur aucune cession de droit d’aucune sorte. <br />5.
        L’Utilisateur reste seul propriétaire des données communiquées à DEPAGNE dans le cadre des présentes.
      </p>

      <h2>9. CONDITIONS DE SUPPRESSION DES ÉLÉMENTS REMIS</h2>
      <p>
        L’Utilisateur reconnaît qu’après l’expiration de la période de deux mois à compter de la non-conclusion ou de la cessation des
        relations contractuelles, DEPAGNE pourra effacer l’ensemble des données archivées dans le cadre du service « TERRA ».
      </p>

      <h2>10. SECURITE - UTILISATION DES IDENTIFIANTS</h2>
      <p>
        1. L’Utilisateur dispose d’un identifiant et d’un mot de passe afin d’accéder et d’utiliser le service « TERRA ».<br />
        2. L’Utilisateur est seul responsable de la préservation et de la confidentialité de son identifiant et de son mot de passe ainsi
        que de l’ensemble des données qu’il transmet.<br />
        3. L’Utilisateur s’engage à prendre toute mesure utile pour en assurer une parfaite confidentialité. <br />4. Toute utilisation de
        son identifiant et de son mot de passe fait présumer de manière irréfragable une utilisation du service « TERRA » par l’Utilisateur
        lui-même. <br />5. L’Utilisateur s’engage à notifier sans délai à DEPAGNE toute communication volontaire ou involontaire à des tiers
        ou vol de son de son identifiant et de son mot de passe.
      </p>

      <h2>11. ACCES ET TELECOMMUNICATIONS</h2>
      <p>
        1. Dans le but de permettre l’envoi à DEPAGNE des informations relatives à la demande devis, l’Utilisateur devra faire son affaire
        personnelle de l’envoi ces éléments par la voie des télécommunications. <br />2. Les coûts d’accès au serveur, l’assistance de
        DEPAGNE et notamment les frais de communication téléphonique ni les éventuels frais d’accès à Internet, restent à la charge de
        chaque partie.
      </p>

      <h2>12. ASSISTANCE</h2>
      <p>
        1. DEPAGNE mettra tous les moyens en œuvre afin d’assurer un service d’assistance comme suit : -Le service d'Assistance Téléphonique
        est disponible de 9h00 à 17h00 du lundi au vendredi (Horaires modulables en fonction de l’activité). -L’Utilisateur dispose d’un
        nombre d'appels téléphoniques illimité auprès de l’équipe de techniciens spécialistes afin de poser toutes questions afférentes à
        l’utilisation du Service.
        <br />
        2. DEPAGNE n’assurera pas les services d’assistance à l’égard : - de toute demande d’assistance n’entrant pas dans l’objet du
        Service, ou - de toute demande d’intervention par un Utilisateur autre qu’un interlocuteur référencé dans le formulaire
        d’inscription, ou - d’une utilisation du service « TERRA » non conforme aux consignes d’utilisation ou à sa destination, ou encore
        une utilisation anormale, quelle que soit la raison (négligence, erreur de manipulation, accident, …) ; - la fourniture d’un réseau
        de télécommunications ne permettant pas l’accès au Service ; - une défaillance de l’un des éléments constituant l’environnement
        logiciel de l’Utilisateur (système d’exploitation, autres logiciels ou progiciels, systèmes réseaux...) ;
      </p>

      <h2>13. SERVICE PROPOSE DANS LE CADRE DE « TERRA »</h2>
      <p>
        1. Le service « TERRA » permet à l’Utilisateur d’obtenir gratuitement par email une notification l’invitant à se connecter au
        service « TERRA » afin d’obtenir son devis personnalisé <br />2. Ce devis ne lie DEPAGNE que pour autant que la commande soit passée
        conformément aux Conditions Générales de Vente de DEPAGNE
      </p>

      <h2>14. MODIFICATIONS DU SERVICE « TERRA » ET DES CONDITIONS GENERALES D’UTILISATION</h2>
      <p>
        1. DEPAGNE se réserve le droit d’apporter des modifications et/ou des améliorations au service « TERRA » proposé.<br />
        2. DEPAGNE se réserve la possibilité de modifier les modalités d’accès au service, tant sur un plan technique que financier.
        <br />3. Par ailleurs, DEPAGNE se réserve le droit d’arrêter définitivement tout ou partie du service « TERRA » proposé. <br />4.
        Les conditions générales opposables sont celles en vigueur lors de l’accès au service « TERRA » <br />5. L’utilisation par
        l’Utilisateur du service « TERRA » vaut acceptation des conditions générales d’utilisation.
      </p>

      <h2>15. RESPONSABILITE</h2>
      <p>
        1. L’Utilisateur reconnaît que DEPAGNE est tenue d’une obligation de moyens et ne saurait être tenue pour responsable des dommages
        directs ou indirects qui pourraient résulter de l’accès ou de l’utilisation du service « TERRA ».<br />
        2. L’Utilisateur reconnaît et accepte, sans que cette liste ne soit exhaustive, que : - il utilise le service « TERRA » à ses
        risques ; - du fait de la particularité du réseau Internet, DEPAGNE ne peut en aucun cas garantir que ce service soit disponible en
        permanence ; - la responsabilité de DEPAGNE ne pourra en aucun cas être recherchée du fait d’une mauvaise utilisation ou de
        l’utilisation frauduleuse par un tiers de l’identifiant et du mot de passe qui est attribué à l’Utilisateur et, plus généralement,
        du service « TERRA » lui-même. <br />3. L’Utilisateur reconnaît qu’en cas de violation des présentes conditions générales
        d’utilisation et/ou des lois ou règlements en vigueur, il s’engage à garantir et à indemniser DEPAGNE contre tout dommage, de
        quelque nature que ce soit, toute plainte ou action en justice émanant de tiers relatif à la diffusion, la transmission par
        l’Utilisateur de contenus rédactionnels et/ou d’informations dans le service « TERRA ».
      </p>

      <h2>16. AUTORISATION LEGALE ET ADMINISTRATIVE</h2>
      <p>
        1. L’Utilisateur garantit DEPAGNE qu’il dispose de l’ensemble des autorisations légales et administratives requises pour
        l’utilisation du Service en vue de leur traitement par DEPAGNE. <br />2. L’Utilisateur procède lui-même auprès de la Commission
        nationale informatique et libertés (CNIL) à toute déclaration préalable liée au traitement des données nominatives résultant de
        l’utilisation du Service. DEPAGNE garantit à l’Utilisateur qu’elle dispose de l’ensemble des autorisations légales et
        administratives requises pour la fourniture du Service. <br />3. L’Utilisateur s’engage en outre à respecter l’ensemble des règles
        légales et réglementaires en vigueur concernant les traitements mais également concernant le réseau Internet. <br />4. Par ailleurs,
        en application des dispositions de la Loi pour la Confiance dans l’Economie Numérique 2004-575 du 21 juin 2004, l’Utilisateur du
        Service, consent expressément à ce que DEPAGNE lui adresse par automate d’appel, télécopieur ou par courrier électronique, toute
        prospection directe de nature commerciale ou autre, aux coordonnées qu’il aura transmises à DEPAGNE. Il peut cependant informer
        DEPAGNE par tout moyen écrit et à tout moment de son refus de recevoir ce type de communication.
      </p>

      <h2>17. INFORMATIQUE ET LIBERTE</h2>
      <p>
        1. Toutes les informations collectées sont nécessaires aux traitements des demandes de l’Utilisateur relatives aux présentes
        Conditions Générales. conformément à la loi n° 78-17 Informatique et Libertés l’Utilisateur bénéficie d’un droit d’accès et de
        rectification auprès des services internes de DEPAGNE accessibles à l’adresse suivante : marketing&#64;depagne.fr
      </p>

      <h2>18. CESSION</h2>
      <p>
        1. Les informations collectées ou obtenues dans le cadre de l’utilisation du service « TERRA » ne pourront faire l’objet d’une
        cession totale ou partielle, à titre onéreux ou gracieux, par l’une des parties, sans l’accord écrit et préalable de l’autre partie.
      </p>

      <h2>19. ASSURANCE</h2>
      <p>
        1. Chacune des parties déclare être assurée auprès d’une compagnie d’assurance notoirement solvable, établie en France, pour toutes
        les conséquences dommageables des actes dont elle pourrait être tenue pour responsable en vertu des prestations réalisées.<br />
        2. Chacune des parties déclare avoir procédé au règlement de toutes les primes exigibles et s’engage à procéder au règlement des
        primes à venir.
      </p>

      <h2>20. LOI</h2>
      <p>
        1. Les présentes conditions générales sont régies par la loi française, il en est ainsi pour les règles de fond comme pour les
        règles de forme.
      </p>

      <h2>21. ATTRIBUTION DE COMPETENCE</h2>
      <p>
        1. EN CAS DE LITIGE ET APRES TENTATIVE DE RECHERCHE D’UNE SOLUTION AMIABLE, COMPETENCE EXPRESSE EST ATTRIBUEE AU TRIBUNAL DE
        COMMERCE DE GRENOBLE, NONOBSTANT PLURALITE DE DEFENDEUR OU APPEL EN GARANTIE, MEME POUR LES PROCÉDURES D’URGENCE OU LES PROCÉDURES
        CONSERVATOIRES, EN REFERE OU PAR REQUETE.
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="ok()">fermer</button>
  </div>
</div>
