import { Component } from '@angular/core';
import { DataStorageService } from './dashboard/services/data-storage.service';
import { DataService } from './configurator/services/data.service';
import { UserService } from './shared/services/user.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { ProjectService } from './dashboard/services/project.service';
import { ToastService } from './shared/services/toast.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'depagne_app';

  constructor(
    private data: DataStorageService,
    private dataService: DataService,
    private toastService: ToastService,
    private userService: UserService,
    private projectService: ProjectService,
    private router: Router,
  ) {}
}
