import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './shared/containers/login/login.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { InscriptionClientComponent } from './shared/containers/inscription-client/inscription-client.component';
import { HasUserGuard } from './shared/guards/has-user.guard';
import { InscriptionCommercialComponent } from './shared/containers/inscription-commercial/inscription-commercial.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    canActivate: [HasUserGuard],
    data: {
      roles: ['user', 'commercial', 'admin'],
    },
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'configurator',
    canActivate: [HasUserGuard],
    data: {
      roles: ['user', 'commercial', 'admin'],
    },
    loadChildren: () => import('./configurator/configurator.module').then((m) => m.ConfiguratorModule),
  },
  { path: 'login', canActivate: [LoggedInGuard], component: LoginComponent },
  { path: 'inscription/client', canActivate: [LoggedInGuard], component: InscriptionClientComponent },
  { path: 'invite/commercial/:token', canActivate: [LoggedInGuard], component: InscriptionCommercialComponent },
  {
    path: 'admin',
    canActivate: [HasUserGuard],
    data: {
      roles: ['admin'],
    },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'visual-admin',
    canActivate: [HasUserGuard],
    data: {
      roles: ['visualadmin'],
    },
    loadChildren: () => import('./visual-admin/visual-admin.module').then((m) => m.VisualAdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
