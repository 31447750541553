import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-ok-modal',
  templateUrl: './simple-ok-modal.component.html',
  styleUrls: ['./simple-ok-modal.component.scss'],
})
export class SimpleOkModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SimpleOkModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      text: string;
      image: string;
      prompt: boolean;
    },
  ) {}

  ngOnInit(): void {}

  ok() {
    this.dialogRef.close({ ok: true });
  }

  cancel() {
    this.dialogRef.close({ ok: false });
  }
}
