import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ClientUser, User } from '@depagne/types';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReadCGUModalComponent } from '../../components/read-cgu-modal/read-c-g-u-modal.component';
import { SimpleOkModalComponent } from '../../components/simple-ok-modal/simple-ok-modal.component';
import { createCodePostalValidator } from '../../validators/form.validators';

@Component({
  selector: 'app-inscription-client',
  templateUrl: './inscription-client.component.html',
  styleUrls: ['./inscription-client.component.scss'],
})
export class InscriptionClientComponent implements OnInit {
  inscriptionForm = this.fb.group(
    {
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      workFunction: ['', Validators.required],
      company: ['', Validators.required],
      address: ['', Validators.required],
      codePostal: ['', Validators.required, createCodePostalValidator],
      city: ['', Validators.required],
      siret: ['', Validators.required],
      phone: ['', Validators.required],
      password: ['', Validators.required],
      password_repeated: ['', Validators.required],
      cgu: [false, [Validators.required, Validators.requiredTrue]],
    },
    {
      validators: checkPasswords,
    },
  );
  error: string | null = null;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {}

  onSubmit() {
    this.userService.createClientUser({ ...this.inscriptionForm.value } as ClientUser).subscribe((result) => {
      console.log(result);
      this.showSubmitSuccess();
      this.router.navigateByUrl('/login');
    });
  }

  readCGU(client: 'client' | 'commercial') {
    const dialogRef = this.dialog.open(ReadCGUModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result.ok) {
      }
    });
  }

  showSubmitSuccess() {
    const dialogRef = this.dialog.open(SimpleOkModalComponent, {
      data: { text: 'Vous allez recevoir un email pour activer votre compte!', prompt: true, image: 'configurator/END.png' },
    });
  }
}

const checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
  let pass = group.get('password')?.value;
  let password_repeated = group.get('password_repeated')?.value;
  return pass === password_repeated ? null : { passwordNoMatch: true };
};
